@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

:root {
  @import apply .light;
}

.dark {
  --color-text-primary: #ffffff;
  --color-text-secondary: #9eacdb;
  --color-text-ternary: #304ffe;
}

.light {
  --color-text-primary: #252a48;
  --color-text-secondary: #9eacdb;
  --color-text-ternary: #304ffe;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

body {
  font-family: "Manrope", sans-serif;
}

.checking {
  font-family: "DM Sans", sans-serif;
}

.login-bg {
  background-color: hsla(0, 0%, 100%, 1);
  background-image: radial-gradient(
      at 11% 92%,
      hsla(161, 100%, 90%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 92% 27%, hsla(231, 100%, 90%, 1) 0px, transparent 50%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  padding: 4rem 0rem 10px 0rem;
}

.login-left {
  position: relative;
  width: 30vw;
  height: 80vh;
  /* height: 100%; */
}
.left-half-circle {
  top: -10vh;
  left: 0vw;
  width: 50vw;
  z-index: 1;
  position: absolute;
}
.bottom-half-circle {
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  max-height: 20vh;
  position: absolute;
  z-index: 0;
}
.bottom-half-circle-signup {
  position: fixed;
  bottom: 0;
  transform: translate(-50%, 0);
  left: 50%;
}
@media screen and (max-width: 1441px) {
  .login-bg {
    background-color: hsla(0, 0%, 100%, 1);
    background-image: radial-gradient(
        at 11% 92%,
        hsla(161, 100%, 90%, 1) 0px,
        transparent 50%
      ),
      radial-gradient(at 92% 27%, hsla(231, 100%, 90%, 1) 0px, transparent 50%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 100vh;
    padding: 0.5em 0rem 40px 0rem;
  }
  .left-half-circle {
    top: 0vh;
    left: 0vw;
    width: 50vw;
    z-index: 1;
    position: absolute;
  }
}

.text-amber {
  color: #f0b501;
}

.text-red {
  color: #dd0043;
}

.text-green {
  color: #00dd9a;
}

.setting-edit-icon path {
  fill: #9eacdb;
}

/* pagination style start */

.arrow-tab {
  color: #373737 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  opacity: 1 !important;
  font-weight: 600;
}
.page-link {
  padding: 0.25rem 0.5rem;
}
.default-tab {
  background-color: #f5f5f5 !important;
  color: #373737 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  opacity: 1 !important;
  border: 1px solid #f5f5f5 !important;
  border-radius: 4px !important;
  font-weight: 600;
}

.active-tab {
  background-color: #304ffe !important;
  color: #ffffff !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  opacity: 1 !important;
  border: 1px solid #f5f5f5 !important;
  border-radius: 4px !important;
}
/* pagination style end */

/* Tooltip start */
.tooltip {
  position: relative;
}

.tooltiptext {
  visibility: hidden;
  background-color: #00dd9a;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  border-radius: 5%;
  z-index: 1;
  position: absolute;
  top: 120%;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
/* Tooltip end */

/* AuthLeftSection card gradient */
.card-gradient {
  background-color: hsla(0, 0%, 100%, 1);
  background-image: radial-gradient(
      at 11% 92%,
      hsla(161, 100%, 90%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 92% 27%, hsla(231, 100%, 90%, 1) 0px, transparent 50%);
}

/* dashboard top funds & calculator grid */
.grid-template-cols-min-300px-max2fr {
  grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
}
