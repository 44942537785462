@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
  width: 5px;
  padding: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cdcfcf;
  border-radius: 10px;
  padding: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}
